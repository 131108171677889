.feed {
  .post {
    background-color: $secondary-background-color;
    margin-bottom: 20px;
    border: 1px solid $elements-border-color;
    border-radius: 5px;
    padding: 10px;

    .repost-info,
    .mood-info {
      font-size: 14px;
      color: $secondary-text-color;
      margin-bottom: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; 

      .icon {
        margin-right: 5px;
      }
    }

    .mood-info {
      display: flex;
      align-items: center;
      .mood-text {
        font-weight: bold;
        margin-left: 5px;
      }
    }

    .post-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .user-details {
        display: flex;
        align-items: center;
        min-width: 0; // Ensures that the flex item can shrink as needed
        overflow: hidden; // Prevents content overflow

        .user-avatar-container {
          @include avatar-style(50px, (-5px, -6px), 11px);
          margin-right: 10px;
          flex-shrink: 0; // Prevents shrinking of the avatar
        }

        .user-info {
          display: flex;
          flex-direction: column;
          min-width: 0; // Allows the text container to shrink
          overflow: hidden;

          .user-name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%; // Prevents the text from overflowing
            color: $interactive-color;
            text-decoration: none;
          }

          .post-date {
            color: $secondary-text-color;
            font-size: 0.9rem;
            flex-shrink: 1; // Allows the date to shrink if needed
          }
        }
      }

      .options-button {
        cursor: pointer;
        font-size: 25px;
        margin-right: 5px;
        color: $interactive-color;
        flex-shrink: 0; // Prevents shrinking of the options button
      }
    }

    .post-image {
      width: 100%;
      max-width: 800px; // Maintain the max width to ensure images don't exceed their natural size
      aspect-ratio: 4 / 3; // Maintain the aspect ratio of 800x600 images (4:3)
      border-radius: 5px;
      background-color: $content-background-color;
      overflow: hidden;
      position: relative;
      margin-top: 10px;
    
      &.loading {
        @include flicker-placeholder();
      }
    
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
        display: block;
        opacity: 0;
        transition: opacity 0.5s ease;
        position: absolute;
        top: 0;
        left: 0;
    
        &.loaded {
          opacity: 1;
        }
      }
    }

    .separator {
      border: none;
      border-top: 1px solid $elements-border-color;
      margin: 10px 0;
    }

    .post-footer {
      margin-top: 10px;
      display: flex;
      justify-content: space-around;

      button {
        @include icon-button();
      }
    }

    .context-menu {
      @include context-menu();
    }

    .reaction-previews {
      @include doodlePreviewsMixin($content-background-color);
    }
  }
}

/* reactions-header */
.reactions-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sort-order-button {
  @include user-action-button();
  font-size: 25px;
  padding: 0.3rem 0.6rem;
  margin-left: 10px;

  .sort-order-icon {
    font-size: 1em;
  }
}

/* Base styles for the notification */
.new-doodles-notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed; /* Ensure it's fixed on the screen */
  left: 50%;
  transform: translateX(-50%) translateY(-10px); /* Center horizontally and adjust position */
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  background-color: $interactive-color; /* Use your defined variable */
  border-radius: 50%; /* Make it a circle */
  color: $primary-background-color; /* Text and icon color */
  cursor: pointer;
  opacity: 0; /* Start invisible */
  transition: opacity 0.5s ease, transform 0.5s ease, top 0.3s ease; /* Add transition for top */
  z-index: 1000; /* Ensure it appears above other elements */
  @media (min-width: 968px) {
    margin-left: 160px;
  }
}

/* Position when at the top of the page */
.new-doodles-notification.top-position {
  top: 65px;
}

/* Position when scrolled down */
.new-doodles-notification.scrolled-position {
  top: 10px;
}

/* Visible state */
.new-doodles-notification.visible {
  opacity: 1; /* Fully visible */
  transform: translateX(-50%) translateY(0); /* Move to the final position */
  animation: idle-swing 3s ease-in-out infinite; /* Apply the idle swing animation */
}

/* Icon styling */
.new-doodles-notification svg {
  font-size: 20px; /* Size of the arrow icon */
}

/* Text styling */
.new-doodles-notification span {
  font-size: 12px; /* Smaller text size */
  text-align: center;
  font-weight: bold;
  line-height: 1.2;
}

/* Idle swing animation */
@keyframes idle-swing {
  0% {
    transform: translateX(-50%) translateY(0); /* Starting position */
  }
  50% {
    transform: translateX(-50%) translateY(5px); /* Move slightly downward */
  }
  100% {
    transform: translateX(-50%) translateY(0); /* Return to starting position */
  }
}