.set-new-password-container {
    background-color: $primary-background-color;
    color: $primary-text-color;
    padding: 20px;
    border-radius: 8px;
    max-width: 400px;
    margin: 0 auto;
  
    .set-new-password-form {
      display: flex;
      flex-direction: column;
  
      input {
        width: 100%;
        padding: 10px;
        border: 1px solid $elements-border-color;
        border-radius: 4px;
        background-color: $secondary-background-color;
        color: $primary-text-color;
        font-size: 16px;
        margin-bottom: 15px; // Adds spacing between inputs
  
        &:focus {
          border-color: $interactive-color;
          outline: none;
          box-shadow: 0 0 0 2px rgba($interactive-color, 0.2);
        }
      }
  
      button {
        @include user-action-button();
        width: 100%;
        margin-top: 10px;
  
        &:disabled {
          background-color: $inactive-color;
          cursor: not-allowed;
        }
  
        @media (hover: hover) and (pointer: fine) {
          &:hover:not(:disabled) {
            background-color: $interactive-color, 10%;
          }
        }
      }
    }
  
    .success-message {
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
      color: $interactive-color;
    }
  
    .error-message {
      margin-bottom: 15px;
      font-size: 14px;
      color: $alert-color;
      text-align: center;
    }
  }