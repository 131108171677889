.signin-container {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  background-color: #121212;
  color: white;
  padding: 0 1rem;

  .info-section, .form-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }

  .info-section {
    text-align: center;

    .logo {
      width: 150px;
      height: 150px;
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }

    .logo.loaded {
      opacity: 1;
    }

    .service-name {
      font-family: 'Montserrat', sans-serif;
      font-size: 48px;
      color: #FFFFFF;
      letter-spacing: 2px;
      position: relative;
    }

    .pre-alpha {
      font-size: 12px;
      position: absolute;
      top: -10px;
      right: -60px;
      color: #FFD700;
    }

    .slogan {
      font-size: 24px;
      color: #FFFFFF;
      margin-top: 0;
      margin-bottom: 2rem;

      .primal-gradient {
        background: linear-gradient(90deg, $logo-first-color, $logo-second-color, $logo-third-color);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .description {
      font-size: 16px;
      color: #BBBBBB;
      max-width: 400px;
    }
  }

  .form-section {
    .error-message {
      background-color: #ff4d4f;
      color: white;
      padding: 0.5rem;
      border-radius: 5px;
      margin-bottom: 1rem;
      width: 100%;
      max-width: 300px;
      text-align: center;
    }

    .signin-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 300px;

      input {
        width: 100%;
        padding: 0.75rem;
        margin-bottom: 1rem;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        box-sizing: border-box;
      }

      .signin-button {
        width: 100%;
        padding: 0.75rem;
        margin-bottom: 0.6rem;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        background-color: #1da1f2;
        color: white;
        text-align: center;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: #0a84d0;
          }
        }
      }

      .forgot-password-link {
        font-size: 13px;
        margin-bottom: 1rem;
        color: #1da1f2;
      }
    }

    .signup-button {
      display: inline-block;
      width: 100%;
      padding: 0.75rem;
      margin-top: 1rem;
      border: none;
      border-radius: 5px;
      font-size: 16px;
      cursor: pointer;
      background-color: #FFFFFF;
      color: #1da1f2;
      text-align: center;
      text-decoration: none;
      box-sizing: border-box;

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: #f0f0f0;
        }
      }
    }
  }
}

// Responsive Design
@media (max-width: 768px) {
  .signin-container {
    flex-direction: column;
    padding: 0 0.5rem; /* Reduced padding */

    .info-section {
      padding-top: 0;
      padding-bottom: 0;
      .logo {
        width: 100px;
        height: 100px;
      }

      .service-name {
        font-size: 36px;
      }

      .slogan {
        font-size: 18px;
      }

      .description {
        font-size: 14px;
      }
    }

    .form-section {
      .signin-form {
        input, .signin-button, .signup-button {
          font-size: 14px;
        }
      }
    }
  }
}