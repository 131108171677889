.user-settings {
  background-color: $primary-background-color;
  color: $primary-text-color;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px; // Increased width to accommodate additional elements
  margin: 0 auto;

  &__title {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__input-group {
    margin-bottom: 20px; // Increased margin for better spacing
    display: flex;
    flex-direction: column;

    &.checkbox-group { // Modifier for checkbox groups
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
    }
  }

  &__label {
    margin-bottom: 5px;
    display: block;
    color: $secondary-text-color;
    font-weight: 600; // Enhanced label visibility
  }

  &__input {
    width: 100%;
    padding: 10px;
    border: 1px solid $elements-border-color;
    border-radius: 4px;
    background-color: $secondary-background-color;
    color: $primary-text-color;
    font-size: 16px; // Increased font size for better readability

    &:focus {
      border-color: $interactive-color;
      outline: none;
      box-shadow: 0 0 0 2px rgba($interactive-color, 0.2); // Enhanced focus state
    }
  }

  &__checkbox {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    cursor: pointer;
    accent-color: $interactive-color; // Modern browsers support accent-color for checkboxes

    &:focus {
      outline: 2px solid rgba($interactive-color, 0.5);
      outline-offset: 2px;
    }
  }

  &__checkbox-label {
    font-size: 14px;
    color: $secondary-text-color;
    cursor: pointer; // Makes the entire label clickable
  }

  &__button {
    @include user-action-button();
    width: 100%;
    margin-top: 10px;

    &:disabled {
      background-color: $inactive-color;
      cursor: not-allowed;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover:not(:disabled) {
        background-color: $interactive-color, 10%;
      }
    }
  }

  &__message {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
    color: $interactive-color;
  }

  &__error {
    margin-bottom: 15px; // Added margin for better spacing
    font-size: 14px;
    color: $primary-text-color; // Define an error color variable
    text-align: center;
  }

  .email-group {
    display: flex;
    flex-direction: column;

    .email-field {
      display: flex;
      align-items: center;

      .user-settings__input {
        flex: 1;
        margin-right: 10px;
      }

      .change-email-button {
        @include user-action-button();
        padding: 10px 15px;
      }
    }
  }

  .email-change-status {
    font-size: 14px;
    color: $primary-text-color;
    margin-top: 5px;
  }
}

// Prompt styles
.email-change-prompt {
  @include prompt-style();

  .prompt-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    h2 {
      margin: 0;
      font-size: 1.5rem;
    }

    .close-button {
      background: none;
      border: none;
      font-size: 1.5rem;
      cursor: pointer;
    }
  }

  .prompt-body {
    padding: 20px;

    p {
      margin-bottom: 20px;
      font-size: 1rem;
      color: $primary-text-color;
    }

    .error-message {
      color: $primary-text-color;
      margin-bottom: 10px;
    }

    .success-message {
      color: $primary-text-color;
      margin-bottom: 10px;
    }

    form {
      .user-settings__input-group {
        margin-bottom: 15px;
        margin-right: 20px;
      }

      .user-settings__button {
        @include user-action-button();
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}