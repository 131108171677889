// mixins.scss

@mixin avatar-style(
  $size,
  $level-position: (-6px, 10px),
  $level-font-size: 10px,
  $level-color: $interactive-color
) {
  width: $size;
  height: $size;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $content-background-color;
  font-size: $size / 2;
  color: $content-background-color;
  position: relative;

  .avatar-image-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    background-color: $content-background-color; // Placeholder background

    .image-loader-container {
      width: 100%;
      height: 100%;

      &.loading {
        @include flicker-placeholder();
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        display: block;
        opacity: 0;
        transition: opacity 0.5s ease;

        &.loaded {
          opacity: 1;
        }
      }
    }
  }

  .avatar-initial {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    font-size: $size / 2;
    font-weight: bold;
    text-transform: uppercase;
    background-color: $content-background-color;
    color: $interactive-color;
  }

  .user-level {
    position: absolute;
    top: nth($level-position, 1);
    right: nth($level-position, 2);
    color: $level-color;
    font-size: $level-font-size;
    font-weight: bold;
    transition: color 0.3s;
  }
}

@mixin plus-icon($size) {
    width: $size;
    height: $size;
    background-color: $interactive-color;
    border-radius: 50%;
    color: $primary-background-color;
    font-size: ($size / 2) - 1;
    text-align: center;
    line-height: $size; // Center vertically
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;

    // Hover styles only for devices that support hover
    @media (hover: hover) and (pointer: fine) {
      &:hover {
          background-color: $element-on-hover-color;
      }
    }
}

@mixin doodlePreviewsMixin($content-background-color) {
  display: flex;
  flex-wrap: wrap;
  padding: 0 16px;

  .entry {
    width: calc(25% - 8px);
    margin: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .username {
      font-size: 12px;
      color: $interactive-color;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 5px;
      display: block;
      width: 100%;
      text-decoration: none;
      cursor: pointer;
      text-align: center;
    }

    .thumbnail-container {
      width: 100%;
      position: relative;
      border-radius: 5%;
      overflow: hidden;

      &.loading {
        @include flicker-placeholder();
        aspect-ratio: 4 / 3;
      }
    
      img {
        background-color: $content-background-color;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit; // Inherit the rounded corners from the container
        opacity: 0; // Initially hidden
        transition: opacity 0.5s ease;
    
        &.loaded {
          opacity: 1;
        }
      }
    }
  }
}

@mixin context-menu() {
  color: $interactive-color;
  background-color: $secondary-background-color;
  border-radius: 10px;
  border: 1px solid $elements-border-color;
  padding: 5px;
  width: 200px;
  z-index: 1000;

  .menu-item {
    padding: 10px 20px;
    font-weight: bold;
    border-radius: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &.danger {
      color: red;
    }

    // Hover styles only for devices that support hover
    @media (hover: hover) and (pointer: fine) {
      &:hover {
          background-color: $element-on-hover-color;
          color: $secondary-background-color;
      }
    }
  }
}

@mixin icon-button() {
    flex: 1; // Equal width for all buttons
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin-right: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s, color 0.3s;

    .icon {
      font-size: 1.5rem;
      margin-right: 8px; // Space between icon and counter
      position: relative;
    }

    .counter {
      font-size: 1rem;
    }

    // Hover styles only for devices that support hover
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $element-on-hover-color;
      }
    }

    &.active {
      color: $interactive-color; // Primary color for active state

      // Hover styles only for devices that support hover
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: $element-on-hover-color; // Slightly darker background on hover
          color: $secondary-background-color;
        }
      }
    }

    &.pressed {
      color: $pressed-color; // Primary color for pressed state

      // Hover styles only for devices that support hover
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: $element-on-hover-color; // Slightly darker background on hover
        }
      }
    }

    &.inactive {
      cursor: default; // Change cursor to default for inactive buttons
      color: $inactive-color; // Grey text for inactive buttons

      // Hover styles only for devices that support hover
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: transparent; // No background color change on hover
        }
      }
    }
}

@mixin list-element() {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid $elements-border-color;
    border-radius: 8px;
    background-color: $secondary-background-color;
    transition: background-color 0.3s;
    position: relative;
    color: $secondary-text-color;
    transition: background-color 0.3s, color 0.3s;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    // Hover styles only for devices that support hover
    @media (hover: hover) and (pointer: fine) {
      &:hover {
          background-color: $element-on-hover-color;
          color: $secondary-background-color;
      }

      &:hover a {
          color: $secondary-background-color;
      }
    }
}

@mixin mini-user-tile() {
    display: flex;
    flex: 1;
    @include list-element();

    .user-avatar-container {
      @include avatar-style(35px, (-5px, -8px), 11px);
      margin-right: 20px;
      flex-shrink: 0;
      margin-bottom: 0;
    }

    .user-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-width: 0;

      .user-name {
        font-size: 1rem;
        font-weight: normal;
        color: $interactive-color;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        align-self: flex-start;
      }

      .user-stats {
        display: flex;
        gap: 1rem;
        font-size: 0.9rem;

        .stat-item {
          display: flex;
          align-items: center;

          .stat-number {
            margin-right: 0.2rem;
          }
        }
      }
    }
    // Hover styles only for devices that support hover
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .user-level {
          color: $secondary-background-color;
        }
      }
  }
}

@mixin user-action-button() {
  display: inline-block; /* Ensures consistent behavior for both button and div */
  padding: 0.5rem 1rem;
  margin: 3px;
  font-size: 0.9rem;
  font-weight: bold;
  color: $interactive-color;
  background-color: $secondary-background-color;
  border: 1px solid $elements-border-color;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s;
  box-sizing: border-box; /* Ensures padding is included in element's total width and height */

  &.active {
    background-color: $primary-text-color;
    color: $secondary-background-color;
  }

  // Hover styles only for devices that support hover
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: $element-on-hover-color;
      color: $secondary-background-color;
    }
  }
}

@mixin close-button() {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: $interactive-color;
  transition: background-color 0.3s, color 0.3s;

  // Hover styles only for devices that support hover
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: $element-on-hover-color;
      color: $secondary-background-color;
    }
  }

  .fa-times {
    font-size: 18px;
  }
}

@mixin flicker-placeholder($color: $secondary-background-color) {
  background: linear-gradient(
    -45deg,
    $color 25%,
    lighten($color, 10%) 50%,
    $color 75%
  );
  background-size: 400% 400%;
  animation: flicker 1.5s ease-in-out infinite;

  @keyframes flicker {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

@mixin progress-bar($width, $height: 10px) {
  width: $width;
  background-color: $secondary-background-color;
  border-radius: 5px;
  border: 1px solid $elements-border-color;
  overflow: hidden;
  height: $height;
  position: relative;

  .progress-bar {
    height: 100%; // Height of the progress bar
    background-color: $interactive-color;
    transition: width 0.3s ease-in-out; // Smooth transition for width changes
  }

  .progress-bar-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 8px;
    color: $primary-text-color;
    font-weight: bold;
  }
}

// Define the prompt-style mixin
@mixin prompt-style($width: 300px, $max-width: 90%) {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $secondary-background-color;
  border: 1px solid $elements-border-color;
  border-radius: 10px;
  z-index: 1000;
  text-align: center;
  width: 300px; // Fix width to prevent horizontal stretching
  max-width: 90%; // Ensure it fits within the viewport
  transition: max-height 0.5s ease, padding-bottom 0.5s ease; // Smooth expansion

  .prompt-header {
    position: relative; /* Positioning context for the close button */
    padding: 10px;

    .close-button {
      @include close-button();
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  .prompt-body {
    padding: 20px;

    h2 {
      margin-bottom: 20px;
      font-size: 1.5rem;
      color: $primary-text-color;
    }

    .prompt-options {
      max-width: 100%;
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-bottom: 20px;
      flex-wrap: wrap;

      .mood-button {
        background: none;
        border: none;
        font-size: 2rem;
        line-height: 1; // Set line-height to 1 to remove extra spacing
        padding: 5px; // Adjust padding to control spacing further
        margin: 0; // Ensure no extra margins are applied
        cursor: pointer;
        transition: transform 0.2s ease, filter 0.5s ease, color 0.3s ease;
        filter: grayscale(0); // Start with no grayscale

        // Initially, all icons will be in the interactive color
        color: $interactive-color;

        // Hover styles only for devices that support hover
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            transform: scale(1.2);
          }
        }

        &:focus {
          outline: none;
        }

        &.disabled {
          filter: grayscale(100%); // Apply grayscale
          pointer-events: none; // Disable hover effects
          opacity: 0.2;
        }

        &.selected {
          transform: scale(1); // Prevent scaling of the selected mood
          filter: none; // Ensure the selected mood remains colored
          opacity: 1;
        }

        span {
          display: block;
        }
      }
    }

    .follow-up-question {
      max-height: 0; // Hidden initially
      overflow: hidden;
      opacity: 0;
      transition: max-height 0.5s ease, opacity 0.5s ease;
      font-size: 1rem;
      color: $primary-text-color;

      &.visible {
        max-height: 300px; // Enough to reveal the content
        opacity: 1;
      }

      p {
        margin-top: 20px;
        font-size: 1.2rem;
        padding: 0 10px;
      }
    }
  }
  .response-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    
    button {
      @include user-action-button();
      flex: 0 1 45%; // Prevent buttons from being too close
    }
  }
}
