// header.scss

.header {
    background-color: $primary-background-color;
    padding: 10px;

    .upper-nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .user-avatar-container {
            @include avatar-style(40px);
        }

        .avatar-placeholder {
          height: 40px;
          visibility: hidden;
        }

        .logo {
          position: absolute; /* Use absolute positioning */
          left: 50%; /* Center horizontally */
          transform: translateX(-50%) translateZ(0); /* Adjust for exact centering */
          font-family: 'Montserrat', sans-serif; /* or 'Oswald', sans-serif */
          font-size: 20px; /* Adjust size as needed */
          color: $primary-text-color; /* or use a gradient */
          text-align: center;
          letter-spacing: 2px; /* Adjust spacing as needed */
        
          -webkit-font-smoothing: antialiased; /* Smoothens font */
          text-rendering: optimizeLegibility; /* Improves text rendering */
          backface-visibility: hidden; /* Prevents rendering glitches on some browsers */
        
          /* Optional Gradient Effect */
          &.logo-gradient {
            background: linear-gradient(90deg, $logo-first-color, $logo-second-color, $logo-third-color);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
    }
}

@media (min-width: 968px) {
  .header {
      .user-avatar {
          display: none; // Hide user avatar from top navigation bar
      }
      .upper-nav .logo {
        margin-left: 160px;
    }
  }
}
