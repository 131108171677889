.recent-score-increments {
  padding-left: 20px;
  background-color: $secondary-background-color;
  border-radius: 10px;
  margin-bottom: 30px;

  .score-increments-list {
    list-style-type: none;
    padding: 0;
    margin: 0;

    .score-increment-item {
      display: flex;
      align-items: center;
      padding: 10px;
      background-color: inherit;
      margin-bottom: 10px;
      white-space: nowrap; /* Prevent wrapping */
      overflow: hidden; /* Prevent overflowing content */
      text-overflow: ellipsis; /* Add ellipsis to cut-off content */

      .timestamp {
        font-size: 0.9rem;
        color: $secondary-text-color;
        margin-right: 15px;
        white-space: nowrap;
        flex-shrink: 0;
      }

      .increment-value {
        font-size: 1rem;
        font-weight: bold;
        color: $primary-text-color;
        margin-right: 10px;
        white-space: nowrap;
        flex-shrink: 0;
      }

      .description {
        font-size: 0.9rem;
        color: $primary-text-color;
        white-space: nowrap;
        margin-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 0;
      }

      .related-user-wrapper {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex; /* Use flex to align the parentheses with the username */
        flex-shrink: 0; /* Prevent shrinking of the related-user area */
        max-width: 150px; /* Ensure max-width is set to allow truncation */
      }

      .related-user {
        font-size: 0.9rem;
        color: $interactive-color;
        font-weight: bold;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px; /* Truncate long usernames */
      }
    }
  }
}