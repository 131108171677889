// lowerNav.scss

.lower-nav {
    background-color: $secondary-background-color;
    border: 1px solid $elements-border-color;
    display: flex;
    justify-content: space-around;
    padding: 5px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    transition: opacity 0.3s ease;

    &.semi-transparent {
      opacity: 0.5;
    }

    .nav-button {
      @include icon-button();
      margin-right: 0;

      .icon-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: background-color 0.3s, color 0.3s;
  
        .icon {
          font-size: 1rem;
          margin-right: 0;
        }

        .button-label {
          margin-left: 7px;
        }
  
        .notifications-counter {
          background-color: $alert-color;
          color: $secondary-background-color;
          border-radius: 50%;
          font-size: 0.65rem;
          padding: 3px;
          height: 10px;
          min-width: 10px;
          text-align: center;
          position: absolute;
          top: -10px;
          right: -5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      
      &:hover {
        background-color: $element-on-hover-color;
        color: $secondary-background-color;
      }
    }
}

@media (min-width: 968px) {
  .lower-nav {
      display: none; // Hide bottom navigation bar
  }
}
