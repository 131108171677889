// chats.scss

.chat-list {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;

  .chat-item {
    @include list-element();
    display: flex;
    align-items: center;
    position: relative;
    
    .user-avatar-container {
      @include avatar-style(50px, (-5px, -6px), 11px);
      margin-right: 0.7rem;
      flex-shrink: 0; // Prevents shrinking of the avatar
    }

    .chat-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 0; // Allows the container to shrink if needed
      overflow: hidden; // Ensures content doesn't overflow
      
      .user-name {
        color: $interactive-color;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%; // Ensures the ellipsis will appear if text is too long
        display: inline-block; // Ensures the element behaves as a block-level element
        transition: color 0.3s ease; // Smooth color transition on hover
      }

      .last-message-time {
        font-size: 0.8rem;
        margin-top: 0.2rem;
        color: $secondary-text-color;
        flex-shrink: 0; // Prevents shrinking of the time
        transition: color 0.3s ease; // Smooth color transition on hover
      }
    }

    .dummy-thumbnail {
      width: 80px;
      height: 60px;
    }

    .image-wrapper {
      width: 80px;
      height: 60px;
      position: relative;
      background-color: $content-background-color;
      border-radius: 5px;
      overflow: hidden;
      margin-left: 0.5rem;
      flex-shrink: 0;

      .image-loader-container {
        width: 100%;
        height: 100%;
        position: relative;

        &.loading {
          @include flicker-placeholder();
        }

        img.message-thumbnail {
          width: 100%;
          height: 100%;
          object-fit: cover;
          display: block;
          opacity: 0;
          transition: opacity 0.5s ease;

          &.loaded {
            opacity: 1;
          }
        }
      }
    }

    .unread-counter {
      font-size: 0.8rem;
      font-weight: bold;
      color: $secondary-background-color;
      background-color: $alert-color;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 5px;
      right: 5px;
      flex-shrink: 0; // Prevents the unread counter from shrinking
    }

    .read-indicator {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $interactive-color;
      position: absolute;
      bottom: 5px;
      right: 5px;

      &.unread {
        border: 2px solid $interactive-color;
        background-color: $secondary-background-color;
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .user-name,
        .last-message-time {
          color: $secondary-background-color; // Change color on hover
        }
      }
    }
  }
}
  
.chat-overlay {
  position: relative;

  .overlay-header {
    .user-avatar-container {
      @include avatar-style(40px, (-5px, -6px), 11px);
      margin-right: 10px;

      img {
        width: 100%;
        height: auto;
        border-radius: 50%;
      }
    }

    .user-name {
      font-size: 1.2rem;
      font-weight: bold;
      color: $interactive-color;
      text-decoration: none;
      flex-grow: 1;
    }

    .close-button {
      @include close-button();
    }
  }

  .chat-content {
    flex: 1;
    padding: 16px;
    padding-top: 72px;

    .message {
      display: flex;
      margin-bottom: 10px;
      align-items: flex-end;

      &.incoming {
        justify-content: flex-start;

        .message-content {
          background-color: $content-background-color;
          border: 1px solid $elements-border-color;
          border-radius: 18px 18px 18px 0;
        }
      }

      &.outgoing {
        justify-content: flex-end;

        .message-content {
          background-color: $content-background-color;
          border-radius: 18px 18px 0 18px;
        }
      }

      .user-avatar-container {
        @include avatar-style(40px, (-5px, -6px), 11px);
        margin-right: 8px;

        img {
          width: 100%;
          height: auto;
          border-radius: 50%;
        }
      }

      .message-content {
        max-width: 60%;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;

        .context-menu {
          @include context-menu();
        }

        &.loading {
          @include flicker-placeholder();
          width: 100%;
          height: auto;
          min-height: 230px; /* Set a minimum height to prevent collapsing */
          border-radius: 18px;
        }

        .message-image {
          width: 100%;
          height: auto;
          max-width: 800px;
          max-height: 600px;
          border-radius: 10px;
          object-fit: cover;
          opacity: 0;
          transition: opacity 0.5s ease;

          &.loaded {
            opacity: 1;
          }
        }

        .message-time {
          font-size: 12px;
          color: $secondary-text-color;
          margin-top: 5px;
          align-self: flex-end;
        }

        .options-button {
          position: absolute;
          top: 5px;
          right: 5px;
          background: none;
          border: none;
          cursor: pointer;
          font-size: 18px;
          color: $secondary-text-color;
          transition: color 0.3s;

          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: $element-on-hover-color;
            }
          }
        }
      }
    }

    .message-enter {
      opacity: 0;
      transform: translateY(20px);
      animation: message-enter 0.5s forwards;
    }

    @keyframes message-enter {
      0% {
        opacity: 0;
        transform: translateY(20px);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .new-message-button {
    @include plus-icon(30px);
    position: absolute;
    bottom: 15px;
    right: 20px;
  }
}

@media (min-width: 968px) {
  .chat-overlay {
    margin-left: 160px;
  }
}
