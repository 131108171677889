// index.scss

// css variable to use with recharts - perhaps will make prettier later
:root {
  --interactive-color: #ffac41;
}

// Variables dark
$primary-background-color: #121212;
$secondary-background-color: #1F1F1F;
$elements-border-color: #333333;
$content-background-color: #F5F5F5;
$interactive-color: var(--interactive-color);
$pressed-color: #F5F5F5;
$primary-text-color: #FFFFFF;
$secondary-text-color: #BBBBBB;
$element-on-hover-color: #ffac41;
$alert-color: #FFFFFF;
$inactive-color: grey;

$logo-first-color: #FFD700;
$logo-second-color: #FF8C00;
$logo-third-color: #FF4500;

// Variables twilight
// $primary-background-color: #222831;
// $secondary-background-color: #393e46;
// $elements-border-color: #333333;
// $content-background-color: #f2f2f2;
// $interactive-color: #f96d00;
// $primary-text-color: #FFFFFF;
// $secondary-text-color: #BBBBBB;
// $element-on-hover-color: #FFA500;
// $alert-color: rgb(255, 196, 0);

// Import mixins
@import './mixins';

// Global styles
body {
    background-color: $primary-background-color;
    color: $primary-text-color;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
}

a {
    color: $interactive-color;
    text-decoration: none;
    transition: color 0.3s;

    &:link,
    &:visited {
      color: $interactive-color;
      text-decoration: none;
    }

    &:hover,
    &:active {
      text-decoration: none;
    }
}

// Common styles
.main-content {
    padding: 10px;
    max-width: 500px;
    margin: 0 auto; // Center the main content horizontally
}

.plus-icon {
  @include plus-icon(50px);
  position: fixed;
  bottom: 55px;
  right: 20px;
  transition: opacity 0.3s ease;

  @media (max-width: 480px) {
    &.semi-transparent {
      opacity: 0.5;
    }
  } 
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  .loader {
    border: 4px solid $primary-text-color;
    border-top: 4px solid transparent;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
}

@media (min-width: 968px) {
  .main-content {
    padding-left: 320px;
  }

  .plus-icon {
    bottom: 20px;
  }
}

// Import component-specific styles
@import './header';
@import './feed';
@import './lowerNav';
@import './menu';
@import './editor';
@import './usersList';
@import './userInfo';
@import './profile';
@import './chats';
@import './notifications';
@import './signInForm';
@import './signUpForm';
@import './moodPrompt';
@import './moodJournal';
@import './userSettings';
@import './userRating';
@import './userProgressDetails';
@import './helpTooltip';
@import './recentScoreIncrements';
@import './userProgressScreen';
@import './challenges';
@import './userSuggestions';
@import './inviteLink';
@import './parentDoodlePreview';
@import './confirmationPrompt';
@import './sectionToggle';
@import './colorPicker';
@import './toolSelector';
@import './signUpPrompt';
@import './resetPasswordForm';
@import './setNewPasswordForm';
@import './closableOverlay';
@import './wall';