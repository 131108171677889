.challenges-page {
    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: $primary-text-color;
    }
  
    .challenges-list {
      display: flex;
      flex-direction: column;
      gap: 1rem;
  
      .challenge-group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
  
        .group-date {
          font-size: 1.2rem;
          font-weight: bold;
          color: $secondary-text-color;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
        }
      }
  
      .challenge-item {
        border: 1px solid $elements-border-color;
        border-radius: 8px;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        border-radius: 5px;
        background-color: $secondary-background-color;
        transition: background-color 0.3s ease;
  
        // Default styles for active challenges
        .challenge-header {
          display: flex;
          align-items: center;
          margin-bottom: 25px; /* Space between header and content */
  
          .reward-icons {
            display: flex;
            align-items: center;
            gap: 15px;
  
            .reward-icon {
              font-size: 1rem;
              color: $interactive-color;
            }
  
            .score-reward {
              font-size: 0.9rem;
              display: flex;
              align-items: center;
              gap: 3px;
              color: $primary-text-color;
            }
          }
  
          .challenge-active-indicator,
          .challenge-completed-indicator {
            margin-left: auto; /* Push the status indicator to the far right */
            display: flex;
            align-items: center;
            gap: 5px;
  
            .challenge-status-text {
              font-size: 0.8rem;
              color: $primary-text-color;
            }
          }
        }
  
        .challenge-content {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          margin-right: 1rem;
  
          .challenge-name {
            font-size: 1.2rem;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: $primary-text-color;
          }
  
          .challenge-description {
            font-size: 0.9rem;
            color: $primary-text-color;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            white-space: normal;
          }
        }
  
        // Styles for completed challenges
        &.completed {
          background-color: $primary-background-color;
  
          .challenge-header {
            .reward-icons {
              .reward-icon {
                color: $secondary-text-color; // Change icon color for completed challenges
              }
  
              .score-reward {
                color: $secondary-text-color; // Change score text color for completed challenges
              }
            }
  
            .challenge-completed-indicator .challenge-status-text {
              color: $secondary-text-color; // Change status text color for completed challenges
            }
          }
  
          .challenge-content {
            .challenge-name,
            .challenge-description {
              color: $secondary-text-color; // Change text color for completed challenges
            }
          }
        }
      }
    }
  }