// main.scss (where the original styles reside)
.main-user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .user-avatar-container {
    @include avatar-style(100px, (-5px, -5px), 15px);
    margin-bottom: 7px;
  }

  .progress-bar-container {
    @include progress-bar(100px); // Width matching the avatar diameter
    margin-bottom: 10px;
  }

  .user-name {
    font-weight: bold;
    margin-bottom: 5px;
    color: $primary-text-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%; // Ensures the text does not overflow the container
  }

  .account-info {
    color: $secondary-text-color;
    margin-bottom: 10px;
    display: inline-block;
  }
}

.user-wall-buttons {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 20px;
}