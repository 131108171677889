.help-tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .help-trigger {
    cursor: pointer;
  }
  
  .help-tooltip {
    font-size: 0.9rem;
    background-color: $secondary-background-color;
    color: $primary-text-color;
    border-radius: 8px;
    padding: 15px;
    z-index: 1000;
    max-width: 300px;
    min-width: 200px;
    text-align: left;
    position: relative;
    border-radius: 10px;
    border: 1px solid $elements-border-color;
  }
  
  .help-tooltip-content {
    position: relative;
  }
  
  .close-btn {
    position: absolute;
    top: -10px;
    right: -10px;
    background: transparent;
    border: none;
    font-size: 1rem;
    cursor: pointer;
    color: $primary-text-color;
  }