/* ColorPicker.css */

/* Base Styles */

.color-picker {
  position: relative;
  display: flex;
  align-items: center;
  font-family: Arial, sans-serif;
}

.color-picker-label {
  margin-right: 8px;
  font-weight: bold;
}

.current-color-button {
  display: flex;
  align-items: center;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  min-width: 150px;
  justify-content: space-between;
  transition: background-color 0.2s, border-color 0.2s;
}

.current-color-button:focus {
  outline: none;
  border-color: #007bff;
}

.current-color-button.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.color-swatch {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 8px;
  border: 1px solid #000;
}

.color-info {
  flex-grow: 1;
  text-align: left;
  display: flex;
  align-items: center;
}

.color-label-text {
  /* Visible by default; controlled via media queries */
}

.dropdown-arrow {
  font-size: 12px;
}

.color-picker-menu {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1000;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 200px; /* Default width for wide screens */
  transform-origin: top;
  opacity: 0;
  transform: scaleY(0);
  transition: opacity 0.2s ease, transform 0.2s ease;
  bottom: 100%; /* Position above the toggle button */
  margin-bottom: 4px;
}

.color-option-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 10px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: left;
  transition: background-color 0.2s;
  font-size: 14px; /* Default font size */
}

.color-option-button:hover:not(:disabled) {
  background-color: #f0f0f0;
}

.color-option-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.color-option-button .color-swatch {
  margin-right: 8px;
  border: 1px solid #ccc;
}

.color-label {
  flex-grow: 1;
}

.current-color-display {
  display: flex;
  align-items: center;
}

.current-color-display .color-swatch {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-right: 8px;
  border: 1px solid #000;
}

.color-info {
  flex-grow: 1;
}

/* Selected Color Highlight */
.color-option-button.selected {
  background-color: #e0e0e0; /* Light gray background for selected color */
}

/* Smooth Animations for Dropdown Menu */
.color-picker-menu {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.color-picker.open .color-picker-menu {
  opacity: 1;
  transform: scaleY(1);
}

/* Media Queries for Mobile Devices */
@media (max-width: 600px) {
  .current-color-button {
    min-width: 80px; /* Shrink the button width */
    padding: 4px 6px;
  }

  /* Always hide color labels in the toggle button on mobile */
  .color-picker .current-color-button .color-label-text {
    display: none;
  }

  /* Center the dropdown menu on mobile screens */
  .color-picker-menu {
    position: fixed; /* Fixed positioning to center on screen */
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scaleY(0); /* Center the menu and hide it initially */
    opacity: 0;
    width: 80vw; /* Set width to 80% of viewport width */
    max-width: 300px; /* Maximum width to prevent excessive size */
    border-radius: 8px;
    transition: opacity 0.3s ease, transform 0.3s ease; /* Smooth transitions */
    z-index: 1001; /* Ensure it's above the overlay */
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2); /* Enhanced shadow for prominence */
    background-color: #fff; /* Ensure background color */
    bottom: auto; /* Remove bottom positioning */
    margin: 0; /* Remove margin */
  }

  /* Show the dropdown menu when open */
  .color-picker.open .color-picker-menu {
    opacity: 1;
    transform: translate(-50%, -50%) scaleY(1);
  }

  /* Add overlay to focus on the dropdown menu */
  .color-picker.open::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* Semi-transparent background */
    z-index: 1000; /* Below the dropdown menu */
  }

  /* Ensure dropdown menu is above the overlay */
  .color-picker-menu {
    z-index: 1001;
  }

  /* Adjust color option button padding and font size for better touch targets */
  .color-option-button {
    padding: 16px 20px; /* Increased padding for touch targets */
    font-size: 16px; /* Larger font for readability */
  }

  /* Ensure dropdown menu does not exceed viewport height */
  .color-picker-menu {
    max-height: 80vh; /* 80% of viewport height */
    overflow-y: auto;
  }

  /* Ensure color labels are visible in the dropdown menu */
  .color-picker-menu .color-label {
    display: block;
  }

  /* Enhance touch usability */
  .color-option-button {
    padding: 16px 20px; /* Increased padding for touch targets */
    font-size: 16px; /* Larger font for readability */
  }

  /* Optional: Add touch-specific hover effects */
  .color-option-button:hover:not(:disabled) {
    background-color: #e0e0e0;
  }

  /* Disabled Toggle Button Styling */
  .current-color-button.disabled {
    background-color: #f9f9f9; /* Light background to indicate disabled state */
    border-color: #ddd; /* Lighter border */
    cursor: not-allowed;
    opacity: 0.6;
  }
}

/* Ensure that on wide screens, color labels are always visible in the toggle button */
@media (min-width: 601px) {
  .color-picker .current-color-button .color-label-text {
    display: inline;
  }

  /* Ensure dropdown menu maintains appropriate width on wide screens */
  .color-picker-menu {
    width: 200px; /* Adjust as necessary */
  }
}