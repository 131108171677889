.user-suggestions-container {
    padding: 1rem;
    border: 1px solid $elements-border-color;
    border-radius: 8px;
    background-color: $primary-background-color;
    margin-bottom: 1rem;
  }
  
  .user-suggestions-heading {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: $primary-text-color;
  }

.user-suggestions-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  
    .suggested-users {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  
    .user-item-container {
        @include mini-user-tile();
        transition: opacity 0.3s ease, transform 0.3s ease, background-color 0.3s, color 0.3s;
        opacity: 1;
        transform: translateY(0);

        &.fade-out {
          opacity: 0;
          transform: translateY(-10px);
          pointer-events: none; // Prevent interaction while fading out
        }
    }
  }
  