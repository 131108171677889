// profile.scss

.profile-page {
  display: flex;
  flex-direction: column;
  padding: 16px;

  h1 {
    font-size: 24px;
    font-weight: bold;
    color: $primary-text-color;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .entry-type {
    margin-bottom: 24px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      border-bottom: 1px solid $elements-border-color;

      h2 {
        margin: 0;
        font-size: 18px;
        color: $secondary-text-color;
      }

      .add-button {
        @include plus-icon(30px);
        position: relative;
        bottom: auto;
        right: auto;
      }
    }

    &__content {
      @include doodlePreviewsMixin($content-background-color);

      @media (max-width: 768px) {
        .entry {
          width: calc(50% - 8px);
        }
      }
    }
  }
}
