/* moodJournal.scss */

.mood-journal-page {
  .mood-journal-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0.5rem;

    .mood-journal-section {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .section-date {
        font-size: 1.2rem;
        font-weight: bold;
        color: $secondary-text-color;
        margin-bottom: 0.5rem;
      }

      .mood-graph {
        border-radius: 10px;
        border: 1px solid $elements-border-color;
        padding: 0px;
      }

      .mood-journal-item {
        @include list-element();
        justify-content: space-between;
        min-height: 60px;
        align-items: center; // Ensure vertical alignment

        &.doodle-entry {
          display: flex;
        }

        .mood-icon {
          color: $interactive-color;
          font-size: 1.5rem;
          margin-right: 0.3rem;
          padding: 10px;
          padding-left: 30px;
          transition: color 0.3s ease;
        }

        .mood-content {
          display: flex;
          flex-grow: 1;
          align-items: center;

          .mood-text {
            font-size: 1rem;
            word-break: break-word;
            max-width: 100%;
            color: $primary-text-color;
            transition: color 0.3s ease;
          }
        }

        .image-wrapper {
          width: 80px;
          height: 60px;
          position: relative;
          background-color: $content-background-color;
          border-radius: 5px;
          overflow: hidden;
          margin-left: 0.5rem;

          .image-loader-container {
            width: 100%;
            height: 100%;
            position: relative;

            &.loading {
              @include flicker-placeholder();
            }

            img.doodle-thumbnail {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block;
              opacity: 0;
              transition: opacity 0.5s ease;
              position: absolute;
              top: 0;
              left: 0;

              &.loaded {
                opacity: 1;
              }
            }
          }
        }

        .mood-time {
          font-size: 0.8rem;
          color: $secondary-text-color;
          transition: color 0.3s ease;
          width: 40px;
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: $element-on-hover-color;

            .mood-icon,
            .mood-text,
            .mood-time {
              color: $secondary-background-color;
            }
          }
        }
      }
    }
  }
}
