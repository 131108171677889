// SectionToggle.scss

.section-toggle {
    display: flex;
    justify-content: center;
    margin: 25px 0;
    border-bottom: 1px solid $elements-border-color; /* Separation line below the tabs */
  
    .toggle-button {
        @include user-action-button(); // Reuse existing button styles

        /* 
            Modify styles to resemble modern tabs.
            - Remove the bottom border to connect the active tab seamlessly with the content below.
            - Round only the top-left and top-right corners.
            - Adjust margins to eliminate gaps between tabs.
        */
        border-radius: 10px 10px 0 0; /* Rounded top corners, square bottom */
        border: 1px solid $elements-border-color; /* Retain border for separation */
        border-bottom: none; /* Remove bottom border to connect with content */
        margin: 0 5px 0 5px; /* Even horizontal spacing, no vertical margin */
        background-color: $secondary-background-color; /* Consistent background */

        /* 
            Active Tab Styling:
            - Distinguish the active tab with a distinct background.
            - Ensure the active tab appears connected to the content by having no bottom border.
            - Change text color for better contrast.
        */
        &.active {
            background-color: $primary-text-color; /* Active tab background */
            color: $secondary-background-color; /* Active tab text color */
            border-bottom: 2px solid $primary-text-color; /* Thicker bottom border to simulate connection */
        }
        
        &.active:hover {
            border-bottom: 2px solid $interactive-color; /* Active tab hover bottom border */
        }

        /* 
            Hover Effect
        */
        @media (hover: hover) and (pointer: fine) {
            &:hover {
                background-color: $element-on-hover-color; /* Hover background */
                color: $secondary-background-color; /* Hover text color */
            }
        }

        /* 
            Focus State:
            - Add an outline for keyboard navigation accessibility.
            - Ensure the outline is distinct and doesn't disrupt the tab appearance.
        */
        &:focus {
            outline: 2px solid $interactive-color; /* Focus outline for accessibility */
            outline-offset: -2px; /* Slight offset to prevent overlap */
        }
    }
}