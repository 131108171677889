/* ParentDoodlePreview.scss */

.parent-doodle-preview-container {
  margin-bottom: 5px;
  border: 1px solid $elements-border-color;
  border-radius: 5px;
  background-color: $secondary-background-color;

  .parent-doodle-preview {
    padding: 10px;
    text-align: center;

    .case-one,
    .case-two {
      display: flex;
      align-items: center;
      justify-content: center;

      .thumbnail-container {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        width: 80px;
        margin: 0 4px;
        cursor: pointer;
        text-align: center;

        .username {
          font-size: 12px;
          color: $interactive-color;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          margin-bottom: 5px;
          display: block;
          width: 100%;
          text-decoration: none;
          cursor: pointer;
        }

        .image-wrapper {
          width: 100%;
          height: 60px;
          position: relative;
          background-color: $content-background-color;
          border-radius: 5px;
          overflow: hidden;

          .image-loader-container {
            width: 100%;
            height: 100%;
            position: relative;

            &.loading {
              @include flicker-placeholder();
            }

            img.thumbnail {
              width: 100%;
              height: 100%;
              object-fit: cover;
              display: block;
              opacity: 0;
              transition: opacity 0.5s ease;
              position: absolute;
              top: 0;
              left: 0;

              &.loaded {
                opacity: 1;
              }
            }
          }
        }

        .icon-turn-down {
          position: absolute;
          top: 50%;
          left: 100%;
          margin-left: 12px;
          margin-top: 5px;
          font-size: 24px;
          color: $secondary-text-color;
        }
      }

      .icon-right {
        display: flex;
        align-items: center;
        font-size: 24px;
        color: $secondary-text-color;
        margin: 0 10px;
        margin-top: 15px;
      }
    }
  }
}