.user-progress-details {
    margin-bottom: 30px;
    padding: 20px;
    border: 1px solid $elements-border-color;
    border-radius: 10px;
    background-color: $secondary-background-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  
    .score-section,
    .level-section {
      margin: 15px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .section-label {
      font-size: 1.2rem;
      font-weight: bold;
    }
  
    .user-score {
      font-size: 3rem;
      font-weight: bold;
      color: $primary-text-color;
    }
  
    .label-with-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      position: relative;
  
      .section-label {
        display: inline-block;
      }
  
      .info-icon {
        font-size: 1rem;
        color: $secondary-text-color;
        vertical-align: middle;
        cursor: pointer;
        margin-left: 5px;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
              color: $interactive-color;
          }
        }
      }
    }
  
    .progress-bar-container {
      @include progress-bar(200px);
      margin-top: 10px;
    }
  }