// src/components/SignUpPrompt.scss

.signup-prompt {
    text-align: center;
    padding: 20px;
    background-color: $secondary-background-color;
    border-radius: 8px;
    max-width: 400px;
    margin: auto;
  
    &__title {
      font-size: 1.8rem;
      color: $primary-text-color;
      margin-bottom: 10px;
    }
  
    &__message {
      font-size: 1rem;
      color: $secondary-text-color;
      margin-bottom: 20px;
    }
  
    &__buttons {
      display: flex;
      justify-content: center;
      gap: 15px;
    }
  
    &__button {
      padding: 10px 20px;
      font-size: 1rem;
      font-weight: bold;
      color: $secondary-background-color;
      background-color: $primary-background-color;
      border-radius: 5px;
      text-decoration: none;
  
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: darken($primary-background-color, 10%);
        }
      }
  
      &--secondary {
        background-color: $secondary-background-color;
        color: $primary-text-color;
        
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: darken($secondary-background-color, 10%);
          }
        }
      }
    }
  }