// usersList.scss

.user-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .user-item {
    @include list-element();
    display: flex;
    align-items: center;

    .user-avatar-container {
      @include avatar-style(50px, (-5px, -6px), 12px);
      margin-right: 15px;
      flex-shrink: 0; // Prevent the avatar from shrinking
    }

    .user-info {
      flex: 1; // Allow this section to take up remaining space
      display: flex;
      flex-direction: column;
      min-width: 0; // Ensures the flexbox container can shrink the user name

      .user-name {
        color: $interactive-color;
        text-decoration: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%; // Ensures it stays within the available space
        margin-bottom: 3px;
      }

      .user-stats {
        display: flex;
        gap: 1rem;
        font-size: 0.9rem;

        .stat-item {
          display: flex;
          align-items: center;

          .stat-number {
            font-weight: bold;
            margin-right: 0.2rem;
          }
        }
      }
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        .user-level {
          color: $secondary-background-color;
        }
      }
    }
  }
}

.user-action-button {
  @include user-action-button();
}
