.signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #121212;
    color: white;
  
    .form-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
      background-color: #1e1e1e;
      border-radius: 8px;
  
      h2 {
        font-size: 24px;
        margin-bottom: 1rem;
      }
  
      .error-container {
        background-color: #ff4d4f;
        color: white;
        padding: 0.5rem;
        border-radius: 5px;
        margin-bottom: 1rem;
        width: 100%;
        max-width: 300px;
        text-align: center;
      }
  
      .signup-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 300px;
  
        input {
          width: 100%;
          padding: 0.75rem;
          margin-bottom: 1rem;
          border: none;
          border-radius: 5px;
          font-size: 16px;
          box-sizing: border-box;
        }
  
        .signup-button {
          width: 100%;
          padding: 0.75rem;
          margin-bottom: 1rem;
          border: none;
          border-radius: 5px;
          font-size: 16px;
          cursor: pointer;
          background-color: #1da1f2;
          color: white;
          text-align: center;
  
          @media (hover: hover) and (pointer: fine) {
            &:hover {
              background-color: #0a84d0;
            }
          }
        }
      }
  
      .terms {
        font-size: 12px;
        color: #bbbbbb;
        text-align: center;
        margin-bottom: 1rem;
  
        a {
          color: #1da1f2;
          text-decoration: none;
  
          @media (hover: hover) and (pointer: fine) {
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
  
      .signin-link {
        display: inline-block;
        width: 100%;
        padding: 0.75rem;
        border: none;
        border-radius: 5px;
        font-size: 16px;
        cursor: pointer;
        background-color: #ffffff;
        color: #1da1f2;
        text-align: center;
        text-decoration: none;
        box-sizing: border-box;
  
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
    }
  }
  
  // Responsive Design
  @media (max-width: 768px) {
    .signup-container {
      .form-section {
        .signup-form {
          input, .signup-button, .signin-link {
            font-size: 14px;
          }
        }
      }
    }
  }
  