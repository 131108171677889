// menu.scss

.menu-screen {
  background-color: $secondary-background-color;
  border-right: 1px solid $elements-border-color;
  padding: 20px;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 65%;
  height: 100%;
  z-index: 1000;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out, visibility 0.3s;

  // Hide menu screen by default
  transform: translateX(-100%);
  visibility: hidden;

  &.show-menu {
    transform: translateX(0);
    visibility: visible;
  }

  .menu-links {
    text-align: left;
    margin-top: 20px; // Add some space above menu links

    a {
      display: flex; // Use flex to align icon and text
      align-items: center;
      padding: 15px 20px; // Increase padding for touch-friendly spacing
      // font-weight: bold;
      border-radius: 10px;
      margin-bottom: 10px; // Increase margin for spacing between menu items
      cursor: pointer;
      text-decoration: none;
      transition: background-color 0.3s, color 0.3s;

      svg {
        margin-right: 10px; // Add space between icon and text
        font-size: 1.2rem; // Adjust icon size
        flex-shrink: 0; // Prevent icon from shrinking
      }

      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: $element-on-hover-color; // Light background color on hover
          color: $secondary-background-color; // Darker text color on hover
        }
      }

      .notifications-counter {
        background-color: $alert-color; // Red background for notifications
        color: $secondary-background-color; // White text
        border-radius: 50%; // Fully rounded
        font-size: 0.75rem; // Smaller font size
        padding: 4px 6px; // Padding to create a circular shape
        min-width: 20px; // Minimum width to ensure it's round
        text-align: center; // Center text
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: auto; // Push the counter to the far right
      }
    }

    .hidden {
      display: none;
    }
  }
}

@media screen and (min-width: 768px) {
  .menu-screen {
    width: 250px; // Adjust as needed for optimal width
  }
}

@media (min-width: 968px) {
  .menu-screen {
    width: 250px; // Adjust width for small desktop
    transform: none; // Remove transform for small desktop
    visibility: visible; // Ensure visibility for small desktop
    position: fixed; // Ensure fixed positioning for small desktop
    transition: none; // Remove transition for small desktop
  }
}
