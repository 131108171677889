.user-rating-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .top-users,
  .other-users {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .separator {
    display: flex;
    justify-content: center;
    margin: 0.1rem 0;
    font-size: 1rem;
    color: $secondary-text-color;
  }

  .user-item-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    .user-rank {
      font-size: 0.7rem;
      font-weight: bold;
      color: $secondary-text-color;
      width: 3rem;
      text-align: right;
    }

    .user-score {
      font-size: 1rem;
      color: $primary-text-color;
      width: 5rem;
      text-align: center;
    }

    .user-item {
      @include mini-user-tile();
    }
  }

  .current-user {
    border-color: $interactive-color !important;
    border-width: 2px !important;
  }
}

@media (max-width: 480px) {
  .user-rating-list {
    .user-item-container {
      display: flex;
      align-items: center;
      gap: 1rem;
  
      .user-rank {
        font-size: 0.7rem;
        font-weight: bold;
        color: $secondary-text-color;
        width: 0.5rem;
        text-align: right;
      }
  
      .user-score {
        font-size: 1rem;
        color: $primary-text-color;
        width: 3rem;
        text-align: center;
      }
    }
  }
}