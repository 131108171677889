.editor-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $primary-background-color;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1005;
  overflow: hidden;

  .editor {
    background-color: $primary-background-color; // Editor background
    padding: 20px;
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around; // Space elements evenly within the editor

    .close-button {
      @include close-button();
      margin-right: 25px;
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 1010; // Ensure the close button is above the canvas
    }

    .canvas-wrapper {
      background-color: $content-background-color; // White background for the drawable area
      width: 800px; // Fixed width
      height: 600px; // Fixed height
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden; // Ensure no overflow
      pointer-events: none; // Prevents capturing clicks

      canvas {
        background-color: $content-background-color; // Canvas background
        width: 100%;
        height: 100%;
        touch-action: none; // Prevent default touch actions
        pointer-events: auto; // Ensures the canvas itself can still be drawn upon
      }
    }

    .editor-toolbar {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 15px 20px;
      background-color: $secondary-background-color;
      border-top: 1px solid $elements-border-color;

      .editing-buttons {
        display: flex;
        gap: 10px;

        button {
          @include user-action-button();
        }
      }

      .post-button {
        button {
          @include user-action-button();
        }

        .draft-button {
          @media (max-width: 768px) {
            display: none;
          }
        }
      }

      .share-on-wall {
        display: flex;
        align-items: center;
        color: $primary-text-color;
        gap: 8px;

        input[type="checkbox"] {
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
      }
    }
  }
}

// Media query for narrow screens
@media (max-width: 480px) {
  .editor-toolbar {
    padding: 10px 15px; // Reduce toolbar padding on mobile
  }

  .editing-buttons {
    gap: 4px !important; // Narrower gap between buttons for compactness

    button {
      //padding: 4px 6px !important; // Reduce padding for more compact buttons
      font-size: 0.8rem !important; // Slightly smaller font for better fit
      margin-left: 0px !important; // Remove margin to maximize available space
      margin-right: 0px !important;
    }
  }

  .post-button button {
    padding: 4px 6px;
    font-size: 0.8rem;
    margin: 0; // Ensure consistency with editing buttons
  }

  .share-on-wall {
    font-size: 0.8rem; // Reduce font size in mobile for label
  }
}