.invite-link-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
  
    .invite-link-input {
      flex: 1;
      padding: 0.5rem;
      border: 1px solid $elements-border-color;
      border-radius: 4px;
      color: $primary-text-color;
      background-color: $secondary-background-color;
    }
  
    .copy-button {
      padding: 0.5rem;
      background-color: $interactive-color;
      border: none;
      border-radius: 4px;
      color: $primary-background-color;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 0.3rem;
  
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          background-color: $element-on-hover-color;
        }
      }
    }
  }
  