.overlay-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's behind the overlay but above other content */
    display: flex;
    justify-content: center;
    align-items: center;
  }

.closable-overlay {
    position: relative; /* Changed from fixed */
    width: 100%;
    max-width: 500px;
    height: 95%;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
    border: 1px solid $elements-border-color;
    border-radius: 5px;
    background-color: $secondary-background-color;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  
    display: flex;
    flex-direction: column;

    /* Mobile styles */
    @media (max-width: 480px) {
        width: 100%;
        height: 100%;
        max-width: none;
        max-height: none;
        border-radius: 0;
        border: none;
        box-shadow: none;
    }

    /* Desktop styles */
    @media (min-width: 968px) {
        border: 1px solid $elements-border-color;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        margin-left: 320px;
    }
  
    .overlay-header {
      position: sticky;
      top: 0;
      left: 0;
      right: 0;
      background-color: $secondary-background-color;
      padding: 15px;
      display: flex;
      align-items: center;
      z-index: 1001;
  
      .close-button {
        @include close-button();
        background-color: $element-on-hover-color;
        color: $secondary-background-color;
        // Hover styles only for devices that support hover
        @media (hover: hover) and (pointer: fine) {
            background-color: $secondary-background-color;
            color: $interactive-color;
            &:hover {
            background-color: $element-on-hover-color;
            color: $secondary-background-color;
        }
  }
      }
  
      .overlay-title {
        font-size: 1.5rem;
        font-weight: bold;
        text-transform: capitalize;
        color: $primary-text-color;
        flex-grow: 1;
        margin-left: 10px;
      }
    }
  
    .overlay-content {
      flex: 1;
      overflow-y: auto;
      padding: 16px;
    }
  
    &.show-overlay {
      opacity: 1;
      visibility: visible;
    }
  }